<template>
    <div>
        <v-row>
            <v-col cols="2">
                <GroupTimeToKill
                    :payload="computeByOrder"
                />
            </v-col>
            <v-col>
                <GroupGhostHp
                    :payload="computeByOrder"
                />
            </v-col>
            <v-col>
                <GroupFailPossibility
                    :payload="computeByOrder"
                />
            </v-col>
            <v-col>
                <GroupGoldCollect
                    :payload="computeByOrder"
                />
            </v-col>
<!--            <v-col>-->
<!--                <GroupGoldSpent-->
<!--                    :payload="computeByOrder"-->
<!--                />-->
<!--            </v-col>-->
            <v-col>
                <GroupDiamondCollect
                    :payload="computeByOrder"
                />
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-card
                max-width="550"
                min-width="550"
                min-height="700"
                max-height="700"
            >
                <v-tabs
                    v-model="tab"
                    background-color="deep-purple accent-4"
                    centered
                    dark
                    icons-and-text1
                >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-gamePlay-kpi">
                        GamePlay KPI
                    </v-tab>
                    <v-tab href="#tab-hero-skill">
                        Hero Skill
                    </v-tab>
                    <v-tab href="#tab-support">
                        Support (test)
                    </v-tab>
                    <v-tab href="#tab-pet">
                        Pets (test)
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab"
                >
                    <v-tab-item
                        :key="1"
                        value='tab-gamePlay-kpi'
                    >
                        <GroupTabGameplayKpi
                            :payload="computeByOrder"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-row> -->
    </div>
</template>

<script>
import GroupTimeToKill from "@/kpi-editor/modules/components/GroupTimeToKill.vue";
import QuestSimulationService from "@/quest-editor/services/QuestSimulationService";
import lodash from "lodash";
import Chain1 from "@/kpi-editor/modules/components/chains/Chain1";
import Chain2 from "@/kpi-editor/modules/components/chains/Chain2";
import Chain9 from "@/kpi-editor/modules/components/chains/Chain9";
import Chain8 from "@/kpi-editor/modules/components/chains/Chain8";
import Chain7 from "@/kpi-editor/modules/components/chains/Chain7";
import Chain6 from "@/kpi-editor/modules/components/chains/Chain6";
import Chain5 from "@/kpi-editor/modules/components/chains/Chain5";
import Chain4 from "@/kpi-editor/modules/components/chains/Chain4";
import Chain3 from "@/kpi-editor/modules/components/chains/Chain3";
import SMPNum from "@/SMPNum";
import GroupDiamondCollect from "@/kpi-editor/modules/components/GroupDiamondCollect.vue";
//import GroupGoldSpent from "@/kpi-editor/modules/components/GroupGoldSpent.vue";
import GroupGoldCollect from "@/kpi-editor/modules/components/GroupGoldCollect.vue";
import GroupGhostHp from "@/kpi-editor/modules/components/GroupGhostHp.vue";
import GroupFailPossibility from "@/kpi-editor/modules/components/GroupFailPossibility.vue";
import Chain10 from "@/kpi-editor/modules/components/chains/Chain10";
import {GlobalEvents, EVENT_TO_UPDATE_BEST_HERO, EVENT_TO_SELECT_HERO, EVENT_TO_SELECT_PET} from "../../../item-editor/events/GlobalEvents";

export default {
    name: "GroupControl",
    components: {
        GroupDiamondCollect, GroupGoldCollect, GroupGhostHp, GroupFailPossibility, GroupTimeToKill
    },
    props: {
        inputConfigSet:Object,
        pService: {type:Object, default: null, required: true},

        ratioAllocatedToSupport: Number,
        ratioAllocatedToSupportSkill: Number,
        ratioAllocatedToPet: Number,
        gameLevel: Number,
        heroBaseLevel: Number,

        isUseSupportSkillDmg: Boolean,
        isUseSupportSkillGold: Boolean,
        isUsePetSkillDmg: Boolean,
        isUsePetSkillGold: Boolean,

        percentControlSupportGoldSkill: Number,
        percentageGoldBonus: Number,

        percentControlPetGoldSkill: Number,
        ratioAllocatedToHeroSkill: Number,

    },

    data: () => ({
        service: null,
        chainRoot: null,
        simulationService: null,
        dailySimulationService: null,
        tab: null,

        heroCount: 1,
        teamBattleInfo: {},

        lastSupportUnlockList: undefined,
    }),
    computed: {
        computeByOrder() {

            const {
                levelThatZoneBossAppear,
                goldRemainFromSupportSpent,
                sprintLevelInfo,
                petSkills,
                heroActiveSkillList,
                heroBossSkillList,
                heroPassiveSkillList,
                supportSkills,
                supportList,
                waveValue,
                gameLevel,
                currentHeroLevel,
                heroBaseDmg,
                heroDmg,
                heroDps,
                //heroCostNextLevel,
                farmingTargetRequiredToUpdateHeroToNextLevel,
                timeToKillGhost,
                timeToKillBoss,
                timeToKillZoneBoss,
                ghostHp,
                normalBossHp,
                zoneBossHp,
                heroBaseDps,
                supportActiveCount,
                supportDps,
                allSupportDps,
                totalGhost,
                totalBoss,
                totalZoneBoss,
                totalEnemies,
                questCount,
                goldDropCurrentLevel,
                ratioAllocatedToPet,
                diamondAvailableForPet,
                percentageGoldAllBonus,
                // diamondBossCollect,
                goldEnding,
                // cumulatedGoldFromQuest,
                // diamondAchievementCollect,
                // totalDiamonds,
                cumulatedGoldWon,
                // cumulatedGoldHeroSpent,
                // goldRemainFromHeroSpent,
                // cumulatedGoldSupportSpent,
                ratioAllocatedToHeroSkill,
                goldAvailableForHeroSkill,
                goldAvailableForHeroes,
                goldAvailableForSupport,
                supportCount,
                supportLevel,
                supportSkillLevel,
                ratioAllocatedToSupport,
                petCount,
                petLevel,
                petList,
                petDisplays,
                petStandardDmg,
                petPassiveDmg,
                petAvgDps,
                hitsPerGhost,
                // cumulatedGoldWonAfterReachingGhostLevel,
                // cumulatedGoldWonAfterReachingBossLevel,
                // cumulatedGoldWonAfterReachingZoneBossLevel,
                // cumulatedGoldWonAfterReachingTotalEnemies,
                heroHp,
                timeHeroPossibleDieWithNormalBoss,
                timeHeroPossibleDieWithZoneBoss,
                bossDps,
                zoneBossDps,
                generatedHeroHpPerSecond,
                expectedGeneratedHpWithBoss,
                expectedGeneratedHpWithZoneBoss,
                heroPossibleSurvive,
                heroPossibleSurviveZoneBoss,
                dmgImpactBonus,
                totalDpsItemBonus,
                goldItemBonus,
                hpItemBonus,
                itemRequirePayload,
                marketWillBonusPayload,
                bossInfo,
                teamBattleInfo,
                heroDmgSessionGainBoss,
                heroDmgSessionFromPet,
                heroHpCapSessionFromSupport,
                heroCount,
                heroList,
                achievementCompleteList,
                isUseAllGoldForMarketPlace,
                heroNextLevelInfo,
                lastSupportUnlockList,
                beginGold,
                goldCanFarm,
                heroLevelAfterFarm,
            } = this.computeChain();

            //============= Item KPI ===========================
            const kpiDmgImpactBonus = dmgImpactBonus;
            const kpiTotalDpsItemBonus = totalDpsItemBonus;
            const kpiGoldItemBonus = goldItemBonus;
            const kpiHpItemBonus = hpItemBonus;

            //============= KPI ================================
            const kpiHeroPossibleUnlockCounter = heroCount;
            const kpiHeroList = heroList;
            const kpiHeroHp = heroHp;
            const kpiTimeHeroPossibleDieWithNormalBoss = timeHeroPossibleDieWithNormalBoss;
            const kpiTimeHeroPossibleDieWithZoneBoss = timeHeroPossibleDieWithZoneBoss;
            const kpiBossDps = bossDps;
            const kpiZoneBossDps = zoneBossDps;
            const kpiGeneratedHeroHpPerSecond = generatedHeroHpPerSecond;
            const kpiExpectedGeneratedHpWithBoss = expectedGeneratedHpWithBoss;
            const kpiHeroPossibleSurvive = heroPossibleSurvive;
            const kpiHeroPossibleSurviveZoneBoss = heroPossibleSurviveZoneBoss;
            const kpiExpectedGeneratedHpWithZoneBoss = expectedGeneratedHpWithZoneBoss;
            const kpiLevelThatZoneBossAppear = levelThatZoneBossAppear;
            const kpiGoldRemainFromSupportSpent = goldRemainFromSupportSpent;
            const kpiGoldAvailableForHeroes = goldAvailableForHeroes;
            const kpiSprintLevelInfo = sprintLevelInfo;
            const kpiPetSkills = petSkills;
            const kpiHeroActiveSkillList = heroActiveSkillList;
            const kpiHeroBossSkillList = heroBossSkillList;
            const kpiHeroPassiveSkillList = heroPassiveSkillList;
            const kpiSupportSkills = supportSkills;
            const kpiSupportList = supportList;
            const kpiWaveValue = waveValue;
            const kpiGameLevel = gameLevel;
            const kpiHeroLevel = currentHeroLevel;
            const kpiHeroBaseDmg = heroBaseDmg;
            const kpiGameplayPlayerTapDamage = heroDmg;
            const kpiHeroWithBonusDps = heroDps;
            //const kpiHeroNextLevel = currentHeroLevel + 1;
            //const kpiHeroCostNextLevel = heroCostNextLevel;
            const kpiHeroNextLevelInfo = heroNextLevelInfo;
            const kpiFarmingTargetRequiredToUpdateHeroToNextLevel = farmingTargetRequiredToUpdateHeroToNextLevel;
            const kpiTimeToKillGhost = timeToKillGhost;
            const kpiTimeToKillBoss = timeToKillBoss;
            const kpiTimeToKillZoneBoss = timeToKillZoneBoss;
            const kpiTimeFarming = SMPNum.multSmpNum(timeToKillGhost, farmingTargetRequiredToUpdateHeroToNextLevel);
            const kpiGhostHp = ghostHp;
            const kpiNormalBossHp = normalBossHp;
            const kpiZoneBossHp = zoneBossHp;
            const kpiHeroBaseDps = heroBaseDps;
            const kpiSupportActiveCount = supportActiveCount;
            const kpiSupportDpsStandard = supportDps;
            const kpiAllSupportDps = allSupportDps;
            const kpiTotalGhost = totalGhost;
            const kpiTotalBoss = totalBoss;
            const kpiTotalZoneBoss = totalZoneBoss;
            const kpiTotalEnemies = totalEnemies;
            const kpiQuestCount = questCount;
            const kpiRatioAllocatedToPet = ratioAllocatedToPet;
            const kpiGoldDropCurrentLevel = goldDropCurrentLevel;
            const kpiDiamondAvailableForPet = diamondAvailableForPet;
            const kpiPercentageGoldAllBonus = percentageGoldAllBonus;
            // const kpiDiamondBossCollect = diamondBossCollect;
            const kpiGoldEnding =  SMPNum.plus(goldEnding, kpiGoldItemBonus);//goldEnding;
            // const kpiCumulatedGoldFromQuest = cumulatedGoldFromQuest;
            // const kpiDiamondAchievementCollect = diamondAchievementCollect;
            // const kpiTotalDiamonds = totalDiamonds;
            const kpiCumulatedGoldWon = cumulatedGoldWon;
            // const kpiCumulatedGoldHeroSpent = cumulatedGoldHeroSpent;
            // const kpiGoldRemainFromHeroSpent = goldRemainFromHeroSpent;
            // const kpiCumulatedGoldSupportSpent = cumulatedGoldSupportSpent;
            const kpiRatioAllocatedToHeroSkill = ratioAllocatedToHeroSkill;
            const kpiGoldAvailableForHeroSkill = goldAvailableForHeroSkill;
            const kpiGoldAvailableForSupport = goldAvailableForSupport;
            const kpiSupportPossibleUnlockCounter = supportCount;
            const kpiSupportPossibleLevel = supportLevel;
            const kpiSupportPossibleSkillLevel = supportSkillLevel;
            const kpiRatioAllocatedToSupport = ratioAllocatedToSupport;
            const kpiRatioAllocatedToHeroes = 100 - kpiRatioAllocatedToSupport;
            const kpiPetCount = petCount;
            const kpiPetLevel = petLevel;
            const kpiPetList = petList;
            const kpiPetDisplays = petDisplays;
            const kpiPetStandardDmg = petStandardDmg;
            const kpiPetPassiveDmg = petPassiveDmg;
            const kpiPetAvgDps = petAvgDps;
            const kpiHitsPerGhost = hitsPerGhost;
            // const kpiCumulatedGoldWonAfterReachingGhostLevel = cumulatedGoldWonAfterReachingGhostLevel;
            // const kpiCumulatedGoldWonAfterReachingBossLevel = cumulatedGoldWonAfterReachingBossLevel;
            // const kpiCumulatedGoldWonAfterReachingZoneBossLevel = cumulatedGoldWonAfterReachingZoneBossLevel;
            // const kpiCumulatedGoldWonAfterReachingTotalEnemies = cumulatedGoldWonAfterReachingTotalEnemies;
            const kpiAchievementCompleteList = achievementCompleteList;
            const kpiUseAllGoldForMarket = isUseAllGoldForMarketPlace;

            // let goldAvailableForHero = new SMPNum(0);
            // if(SMPNum.greaterThan(kpiCumulatedGoldWon, goldAvailableForSupport)){
            //     goldAvailableForHero = SMPNum.minus(kpiCumulatedGoldWon, goldAvailableForSupport);
            // }
            // const kpiGoldAvailableForHero = goldAvailableForHero;

            this.setLastUnlockSupportList(lastSupportUnlockList);

            console.log("computing..ended...");


            const kpiResultSet = {
                kpiHeroPossibleUnlockCounter,
                kpiHeroList,
                kpiHeroHp,
                kpiTimeHeroPossibleDieWithNormalBoss,
                kpiTimeHeroPossibleDieWithZoneBoss,
                kpiBossDps,
                kpiZoneBossDps,
                kpiGeneratedHeroHpPerSecond,
                kpiExpectedGeneratedHpWithBoss,
                kpiExpectedGeneratedHpWithZoneBoss,
                kpiHeroPossibleSurvive,
                kpiHeroPossibleSurviveZoneBoss,
                kpiLevelThatZoneBossAppear,
                kpiGoldRemainFromSupportSpent,
                kpiGoldAvailableForHeroes,
                kpiSprintLevelInfo,
                kpiPetSkills,
                kpiHeroActiveSkillList,
                kpiHeroBossSkillList,
                kpiHeroPassiveSkillList,
                kpiSupportSkills,
                kpiSupportList,
                kpiGoldDropCurrentLevel,
                kpiWaveValue,
                kpiGameLevel,
                kpiHeroLevel,
                kpiHeroBaseDmg,
                kpiGameplayPlayerTapDamage,
                kpiHeroWithBonusDps,
                //kpiHeroNextLevel,
                //kpiHeroCostNextLevel,
                kpiHeroNextLevelInfo,
                kpiFarmingTargetRequiredToUpdateHeroToNextLevel,
                kpiTimeToKillGhost,
                kpiTimeToKillBoss,
                kpiTimeToKillZoneBoss,
                kpiTimeFarming,
                kpiGhostHp,
                kpiNormalBossHp,
                kpiZoneBossHp,
                kpiHeroBaseDps,
                kpiSupportActiveCount,
                kpiSupportDpsStandard,
                kpiAllSupportDps,
                kpiTotalGhost,
                kpiTotalBoss,
                kpiTotalZoneBoss,
                kpiTotalEnemies,
                // kpiCumulatedGoldWonAfterReachingGhostLevel,
                // kpiCumulatedGoldWonAfterReachingBossLevel,
                // kpiCumulatedGoldWonAfterReachingZoneBossLevel,
                // kpiCumulatedGoldWonAfterReachingTotalEnemies,
                kpiQuestCount,
                // kpiCumulatedGoldFromQuest,
                kpiPercentageGoldAllBonus,
                kpiCumulatedGoldWon,
                // kpiCumulatedGoldHeroSpent,
                // kpiGoldRemainFromHeroSpent,
                // kpiCumulatedGoldSupportSpent,
                kpiGoldEnding,
                kpiRatioAllocatedToHeroSkill,
                kpiGoldAvailableForHeroSkill,
                kpiRatioAllocatedToSupport,
                kpiRatioAllocatedToHeroes,
                kpiGoldAvailableForSupport,
                // kpiGoldAvailableForHero,
                kpiSupportPossibleUnlockCounter,
                kpiSupportPossibleLevel,
                kpiSupportPossibleSkillLevel,
                // kpiDiamondBossCollect,
                // kpiDiamondAchievementCollect,
                // kpiTotalDiamonds,
                kpiRatioAllocatedToPet,
                kpiDiamondAvailableForPet,
                kpiPetCount,
                kpiPetLevel,
                kpiPetList,
                kpiPetDisplays,
                kpiPetStandardDmg,
                kpiPetPassiveDmg,
                kpiPetAvgDps,
                kpiHitsPerGhost,

                kpiDmgImpactBonus,
                kpiTotalDpsItemBonus,
                kpiGoldItemBonus,
                kpiHpItemBonus,
                itemRequirePayload,
                marketWillBonusPayload,
                bossInfo,
                teamBattleInfo,
                heroDmgSessionGainBoss,
                heroDmgSessionFromPet,
                heroHpCapSessionFromSupport,
                kpiAchievementCompleteList,
                kpiUseAllGoldForMarket,

                beginGold,
                goldCanFarm,
                heroLevelAfterFarm,
            };

            this.$emit("onKpiUpdate", kpiResultSet);

            return kpiResultSet;
        },

    },
    methods: {
        computeChain() {

            let originalPayload = lodash.cloneDeep(this.inputConfigSet);

            const petLevel = 0;
            const skillList = this.$store.state.skillList;
            const hitBossPerSecond = this.$store.state.ratios.hitBossPerSeconds;
            const hitPerSecond = this.$store.state.ratios.hitPerSeconds;
            const achievementList = this.$store.state.achievementList;

            originalPayload["petLevel"] = petLevel;
            originalPayload["skillList"] = skillList;
            originalPayload["hitBossPerSecond"] = hitBossPerSecond;
            originalPayload["hitPerSecond"] = hitPerSecond;
            originalPayload["achievementList"] = achievementList;

            originalPayload.itemImpactBonus = this.$store.state.itemImpactBonus;
            originalPayload.marketItemWillBonus = this.$store.state.marketItemWillBonus;
            originalPayload.dailyGameLevelPerDay = this.$store.state.ratios.dailyGameLevelPerDay;

            //define bossInfo, because gameLevel have changed
            let gameLevel = originalPayload.gameLevel;
            let bossInfo = this.service.getBossInfoByGameLevel(gameLevel);
            originalPayload.bossInfo = bossInfo;

            let heroCount = this.service.getHeroUnlockedCount(gameLevel);

            originalPayload.heroList = this.service.getDisplayHeroList(heroCount, originalPayload.heroLevel);

            if(heroCount < this.heroCount && !this.service.isHeroUnlockYet(this.teamBattleInfo, gameLevel)){
                this.teamBattleInfo = this.getTeamBattleInfo(bossInfo, heroCount, gameLevel, originalPayload.heroList);
            } else {
                this.service.setCurrentHeroStore(this.teamBattleInfo);
                this.service.setCurrentBossStore(bossInfo, gameLevel);
                this.service.setSelectPetActive(this.teamBattleInfo.petData);
            }

            //update hero level
            let currentHeroLevel = this.service.getCurrentHeroLevel();
            if(originalPayload.heroHistory){
                originalPayload.heroList.forEach(hero => {
                    let hist = originalPayload.heroHistory.find(h => h.m_iID === hero.m_iID);
                    if(hist){
                        hero.possibleLevel = hist.possibleLevel;
                        if(hero.m_iID === this.teamBattleInfo.heroData.m_iID) {
                            currentHeroLevel = hero.possibleLevel;
                        }
                    }
                });
            }

            this.heroCount = heroCount;
            originalPayload.teamBattleInfo = this.teamBattleInfo;
            originalPayload.heroCount = this.heroCount;

            //heroLevel which selected
            originalPayload.currentHeroLevel = currentHeroLevel;

            if (this.chainRoot) {
                let returnPayload = this.chainRoot.handleRequest(originalPayload);
                return returnPayload;
            }
        },

        updateBestHero(){
            const {gameLevel, heroLevel} = this.inputConfigSet;

            let bossInfo = this.service.getBossInfoByGameLevel(gameLevel);
            this.heroCount = this.service.getHeroUnlockedCount(gameLevel);

            let heroCount = this.service.getHeroUnlockedCount(gameLevel);
            let heroList = this.service.getDisplayHeroList(heroCount, heroLevel);

            this.teamBattleInfo = this.getTeamBattleInfo(bossInfo, this.heroCount, gameLevel, heroList, this.lastSupportUnlockList);
        },

        selectHero(heroId){
            this.service.setCurrentHeroToTeamBattleInfo(heroId, this.teamBattleInfo);
        },

        selectPet(petId){
            let petData = this.service.getPetDataById(petId);
            this.teamBattleInfo.petData = petData;
            this.service.setSelectPetActive(this.teamBattleInfo.petData);
        },

        getTeamBattleInfo(bossInfo, heroCount, gameLevel, kpiHeroList, supportsHaveUnlock){
            let teamBattleInfo = this.service.getTeamsBattleData(bossInfo ,heroCount , gameLevel, kpiHeroList, supportsHaveUnlock);

            this.service.setCurrentHeroStore(teamBattleInfo);
            this.service.setCurrentBossStore(bossInfo, gameLevel);
            this.service.setSelectPetActive(teamBattleInfo.petData);

            return teamBattleInfo;
        },

        setLastUnlockSupportList(supportList){
           this.lastSupportUnlockList = supportList;
        },
    },
    created() {
        this.service = this.pService;

        this.simulationService = new QuestSimulationService(
            lodash.cloneDeep(this.$store.state.questList)
            , this.service
            , this.$store.state.progressTitle
            , this.$store.state.progressMainTitle
            , this.$store.state.tutorialTitle
        );

        this.dailySimulationService = new QuestSimulationService(
            lodash.cloneDeep(this.$store.state.dailyQuestList)
            , this.service
            , this.$store.state.progressTitle
            , this.$store.state.progressMainTitle
            , this.$store.state.tutorialTitle
        );

        let chain10 = new Chain10(this.service);
        let chain9 = new Chain9(this.service, chain10);
        let chain8 = new Chain8(this.service, chain9);
        let chain7 = new Chain7(this.service, chain8);
        let chain6 = new Chain6(this.service, chain7);
        let chain5 = new Chain5(this.service, chain6);
        let chain4 = new Chain4(this.service, chain5);
        let chain3 = new Chain3(this.service, chain4);
        let chain2 = new Chain2(this.service, chain3, this.simulationService, this.dailySimulationService);
        chain10.successor = chain3;
        this.chainRoot = new Chain1(this.service, chain2);

        this.updateBestHero();
        GlobalEvents.$on(EVENT_TO_UPDATE_BEST_HERO, () => this.updateBestHero());
        GlobalEvents.$on(EVENT_TO_SELECT_HERO, (heroId) => this.selectHero(heroId));
        GlobalEvents.$on(EVENT_TO_SELECT_PET, (petId) => this.selectPet(petId));
    }
}
</script>

<style scoped>

</style>