<template>
    <v-card
        min-width="950"
        class="mt-2"
        elevation="0"
    >
        <div class="font-weight-black">SIMULATOR MARKET ITEM REQUIRE</div>
        <v-container class="grey lighten-5">
            <v-form ref="form" class="mx-2" lazy-validation>
                <v-label>
                    These data will use for automate item generation on Template Pack name {{this.$store.state.templatePack.packSelected}}
                </v-label>
                <div v-if="chartData.length>0">Last simulator result in charts</div>
                <v-row v-if="chartData.length>0">
                    <v-col>
                        <v-frappe-chart
                                :height="chartHeight"
                                type="line"
                                :labels="chartLabels"
                                :data="chartData"
                                :colors="chartColors"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="generateMsg">
                    <v-col cols="0">
                        <v-card-text>
                            {{generateMsg}}
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <h4>Start Level</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-text-field
                                        v-model="config.startLevel"
                                        label="level"
                                        clearable
                                        dense
                                        type="number"
                                        :rules="rulesNotEmptyAndHigherThan('start level', 0)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <h4>End Level</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-text-field
                                        v-model="config.endLevel"
                                        label="level"
                                        clearable
                                        dense
                                        type="number"
                                        :rules="rulesNotEmpty('end level')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <h4>Simulate level per step</h4>
                        <v-row no-gutters dense class="my-3 ml-3">
                            <v-col>
                                <v-text-field
                                        v-model="config.stepLevel"
                                        label="level"
                                        clearable
                                        dense
                                        type="number"
                                        :rules="rulesNotEmptyAndHigherThan('step level', 0)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                                class="mr-2"
                                color="success"
                                @click="startSimulator()"
                        >
                            Simulator
                        </v-btn>
                        <v-btn
                                class="mr-2"
                                color="blue"
                                @click="exportResult()"
                        >
                            Save
                        </v-btn>
                        <v-btn
                                class="mr-2"
                                color="red"
                                @click="close()"
                        >
                            Close
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeoutSnackbar"
                :color="colorSnackbar"
                centered
        >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
    import KpiItemRequireUtils from "./../../Utils/KpiItemRequireUtils.js";
    import { VFrappeChart } from "vue-frappe-chart"
    import ItemRequirePercentageModel from "../../classes/ItemRequirePercentageModel";
    import SimulatorItemRequireLoader from "./../../dao/SimulatorItemRequireLoader";
    import {GlobalEvents, FINISH_ONE_SIMULATOR} from "@/item-editor/events/GlobalEvents";


    export default {
    name: "PanelSimulatorMarketItemRequireByLevel",
    props:["payload"],
    components: {
        VFrappeChart,
    },
    created() {
        this.kpiItemRequire = new KpiItemRequireUtils(this.$store.state.gamePlayDataService);
        this.results = this.$store.state.simulatorItemRequireList;
        this.config = this.$store.state.simulatorItemRequireConfig;
        setTimeout(() =>  this.initChartDatasheet(), 250);

        GlobalEvents.$on(FINISH_ONE_SIMULATOR,(level)=> this.onFinishOneSimulator(level));
    },
    data() {
        return {
            config:{
                startLevel: 1,
                stepLevel: 1,
                endLevel: 10000
            },
            isLoading:false,
            snackbar: false,
            textSnackbar: '',
            colorSnackbar: 'success',
            timeoutSnackbar: 2000,
            generateMsg:'',
            kpiItemRequire: null,
            results: [],
            chartLabels: [],
            chartData: [],
            chartColors:[
                //"#05472A",
                "green",
                //"#AA4A44",
                "red"],
            chartHeight: 550,
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        startSimulator() {
            this.isLoading = true;
            this.results = [];
            setTimeout(() => this.waitToAvoidBugGenerate(), 200);
        },
        exportResult(){
            this.$store.state.simulatorItemRequireList = this.results;
            //this.$store.commit(this.$store.state.commitActions.PUSH_SIMULATOR_ITEM_REQUIRE, this.results);
            let packSelected = this.$store.state.templatePack.packSelected;
            let pushData = {
                config: this.config,
                list: this.results
            }
            SimulatorItemRequireLoader.pushSimulatorItemRequireData(pushData, packSelected);
            this.setGenerateMsg('Saved!');
        },
        validateInputInNumber(){
            this.config.startLevel = parseInt(this.config.startLevel);
            this.config.endLevel = parseInt(this.config.endLevel);
            this.config.stepLevel = parseInt(this.config.stepLevel);
        },
        waitToAvoidBugGenerate(){
            let successful = this.$refs.form.validate();
            if (successful) {
                this.validateInputInNumber();

                if(this.config.startLevel >= this.config.endLevel){
                    this.setGenerateMsg("end level need to bigger than start level");
                    this.isLoading = false;
                } else {
                    this.setGenerateMsg("start simulator ...");
                    this.simulatorInput(this.config.startLevel, this.config.endLevel);
                }
            } else {
                this.setGenerateMsg("Input field not valid");
                this.isLoading = false;
            }

        },
        simulatorInput(level, endLevel){
            this.setGenerateMsg(`simulate on level ${level} finish ${this.formatNumber(level*100.00/endLevel)}%`);
            this.$emit('simulatorLevel', level);
            //setTimeout(() =>  this.defineRequirePercentageAndNextStep(level, endLevel, stepLevel), 160);
        },

        onFinishOneSimulator(level){
            this.defineRequirePercentageAndNextStep(level, this.config.endLevel, this.config.stepLevel);
        },

        defineRequirePercentageAndNextStep(level, endLevel, stepLevel){
            const {itemRequirePayload, marketWillBonusPayload} = this.payload;
            let requireItem = this.kpiItemRequire.getMarketItemMatchWithGamePlayRequireWithoutAnyMarketItem(level, itemRequirePayload, marketWillBonusPayload);

            this.results.push(new ItemRequirePercentageModel(level, requireItem));

            if(level>=endLevel){
                this.setGenerateMsg("finish simulator");
                this.initChartDatasheet();
                this.isLoading = false;
            } else {
                level+=stepLevel;
                setTimeout(() =>  this.simulatorInput(level, endLevel, stepLevel), 200);
            }
        },
        initChartDatasheet(){
            if(this.results.length === 0) return;

            this.chartLabels = [];
            this.chartData = [];
            let hpValueSheets = [];
            let dmgValueSheets = [];
            let maxSheetPoint = 10;
            let stepIndex = 1;
            if(this.results.length > maxSheetPoint){
                stepIndex = Math.floor(this.results.length / maxSheetPoint);
            }
            for(let index = 0; index < this.results.length; index+=stepIndex){
                let rs = this.results[index];
                this.chartLabels.push('lv. '+rs.level);
                hpValueSheets.push(this.formatNumber(rs.hpRequirePercent));
                dmgValueSheets.push(this.formatNumber(rs.dmgRequirePercent));
            }
            this.chartData.push({
                name: 'Require Hp(%)',
                values: hpValueSheets
            });
            this.chartData.push({
                name: 'Require Dmg(%)',
                values: dmgValueSheets
            });
        },
        formatNumber (num) {
            return parseFloat(num).toFixed(2)
        },

        rulesNotEmptyAndHigherThan (fieldName, min) {
            return [
                v => (!!v || fieldName + ' is required!') && (v > min || 'Invalid')
            ];
        },
        rulesNotEmptyAndBetween (fieldName, min, max) {
            return [
                v => (!!v || fieldName + ' is required!') && (v >= min && v <= max || 'Invalid')
            ];
        },
        rulesNotEmpty (fieldName) {
            return [
                v => !!v || fieldName + ' is required!'
            ];
        },
        setGenerateMsg(msg){
            this.generateMsg = msg;
        },
    }
}
</script>

<style scoped>

</style>